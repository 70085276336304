import { GetAllResponse } from '@type/common'
import { CongressChallenger } from '@type/congress-challenger'
import { getAxios } from './api'

export interface CongressMember {
  id?: string
  name: string
  bioguideId: string
  district: string
  state: string
  imageUrl?: string
  photoUrl?: string
  party: string
  congressType: string
  committees: CongressionalCommittee[]
  subcommittees: CongressionalSubcommittee[]
  congressTerms: MemberTerm[]
}

export interface CongressionalCommittee {
  id: string
  name: string
  title: string
  thomasId: string
  type: string
  url: string
  minorityUrl: string
  createdAt: string
  subcommittees: CongressionalSubcommittee[]
  congressMembers: CongressMember[]
  updatedAt: string
  deletedAt: any
}

export interface CongressionalSubcommittee {
  id: string
  name: string
  title: string
  thomasId: string
  address: string
  phone: string
  createdAt: string
  updatedAt: string
  congressMembers: CongressMember[]
  committee: CongressionalCommittee
  deletedAt: any
}

export interface UserCongressDetail {
  myChallengersRepresentative: Array<CongressChallenger>
  myChallengersSenator1: Array<CongressChallenger>
  myChallengersSenator2: Array<CongressChallenger>
  myRepresentatives: any
  senators: Array<CongressMember>
}

export interface Address {
  city?: string
  zipCode: string
  line1?: string
  state?: string
  line1?: string
}

export interface MemberTerm {
  role: string
  session: Session
}

export interface Session {
  congressNumber: number
  startYear: number
  endYear: number
}

export const CongressType = ['REPRESENTATIVE', 'SENATOR']
export const CONGRESS_API_PATH = '/congress'
export const API_URL_USER_CONGRESS_DETAIL = `${CONGRESS_API_PATH}/detail`
export const ADDRESS_API_PATH = '/address'
export const ADDRESS_API_CITIES = `${ADDRESS_API_PATH}/cities`
export const ADDRESS_API_ZIPCODE = `${ADDRESS_API_PATH}/zipcode`
const API_URL_SENATORS = `${CONGRESS_API_PATH}/senators`
const API_URL_MY_REPRESENTATIVE = `${CONGRESS_API_PATH}/my-representative`
export const getCongressMembers = async (params): Promise<GetAllResponse<CongressMember>> => {
  const { data } = await getAxios().get(`${CONGRESS_API_PATH}/members`, {
    params,
  })
  return data
}

export const getCongressMemberDetail = async (id: string): Promise<CongressMember> => {
  const { data } = await getAxios().get(`${CONGRESS_API_PATH}/${id}`)
  return data
}

export const getSenators = async (state: string) => {
  const { data } = await getAxios().get<Array<CongressMember>>(`${API_URL_SENATORS}/${state}`)
  return data
}

export const getUserCongressDetail = async (
  params: Partial<Address>,
  userId: string,
): Promise<any> => {
  const { data } = await getAxios().get<UserCongressDetail>(
    `${API_URL_USER_CONGRESS_DETAIL}/${userId}`,
    { params },
  )
  return data
}

export const getMyRepresentatives = async (params: Partial<Address>): Promise<any> => {
  const { data } = await getAxios().get<any>(API_URL_MY_REPRESENTATIVE, { params })
  return data
}
export const getUserAddress = async () => {
  const { data } = await getAxios().get<Partial<Address>>(`${ADDRESS_API_PATH}`)
  return data
}

export const saveUserSate = async (state: string) => {
  const { data } = await getAxios().post<any>(`${ADDRESS_API_PATH}/${state}`)
  return data
}

export const saveUserAddress = async (address: Partial<Address>) => {
  const { data } = await getAxios().post<any>(`${ADDRESS_API_PATH}`, address)
  return data
}
export const getCitiesByState = async (state: string) => {
  const { data } = await getAxios().get<Array<{ city: string }>>(`${ADDRESS_API_CITIES}/${state}`)
  return data
}
export const getZipCodesByStateAndCity = async (state: string, city: string) => {
  const { data } = await getAxios().get<Array<{ zipcode: string }>>(
    `${ADDRESS_API_ZIPCODE}/${state}/${city}`,
  )
  return data
}

export const editCongressRepresentative = async (
  values: CongressMember,
): Promise<CongressMember> => {
  const { data } = await getAxios().put<CongressMember>(`${CONGRESS_API_PATH}/${values.id}`, values)
  return data
}
