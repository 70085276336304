import { SurfingConnection } from '@type/connection'
import { getAxios } from './api'

const API_URL = '/story-engine/surfing'

export const surfingCheckConnection = async (params: { pageType?: string; userId?: string }) => {
  const { data } = await getAxios().get<SurfingConnection>(`${API_URL}/connections`, {
    params,
  })
  return data
}
