/* eslint-disable no-console */
// utils/gtm.ts
interface EventData {
  event: string
  [key: string]: any
}

export const pushToDataLayer = (eventData: EventData) => {
  if (window.dataLayer) {
    window.dataLayer.push(eventData)
  } else {
    console.warn('GTM dataLayer is not available')
  }
}
