import { getAxios } from '@api/api'

export const SURFING_SECTION_API_PATH = '/story-engine/surfing-sections'

export type SurfingSection = {
  id?: string
  tricoderVideoEmbedUrl?: string
  tricoderVideoUrl?: string
}
export const getSurfingSectionByIdApi = async (id: string): Promise<SurfingSection> => {
  const { data } = await getAxios().get(`${SURFING_SECTION_API_PATH}/${id}`)
  return data
}

export const getSurfingSectionVideoInfo = async (id, params) => {
  const { data } = await getAxios().get(`${SURFING_SECTION_API_PATH}/video-info/${id}`, {
    params,
  })
  return data
}
