import { getAxios } from '@api/api'
import { UpdateUserDto } from '@type/dto'

export type UserValidResponse = {
  valid?: boolean
}

export const updateUserByAdmin = (updateUserDto: UpdateUserDto) => {
  const { id, ...user } = updateUserDto
  return getAxios().put(`/user/${id}`, user)
}

// Api call for uploading user profile picture
export async function uploadUserProfilePicByAdmin(file: File, userId: string) {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await getAxios().post(`/user/admin/profile-picture/${userId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data
}

// Api call for deleting user profile picture
export async function deleteUserProfilePicByAdmin(userId: string) {
  const { data } = await getAxios().delete(`/user/admin/profile-picture/${userId}`)
  return data
}

export async function checkUserIsValid(userId: string): Promise<UserValidResponse> {
  const { data } = await getAxios().get(`/user/valid/${userId}`)
  return data
}
