/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
// eslint-disable-file
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { WS_EVENT, WS_URL } from '@constant/index'
import ROUTES from '@constant/Routes'
import { getDeviceType } from '@util/device'
import { PageType } from '@template/GameDashboard/types'
import { WebsocketData } from '@type/websocket'

const W3CWebSocket = require('websocket').w3cwebsocket

const RETRY_CONNECTION_POLLING_FREQUENCY = 6e4
export const useWebSocket = (onMessage?: (_: WebsocketData) => void) => {
  const wsRef = useRef(null)
  const pingIntervalRef = useRef(null)
  const router = useRouter()

  const [pageType, setPageType] = useState(PageType.TRICODER)
  const [ssid, setSsid] = useState(null)
  const [userId, setUserId] = useState(null)
  const pollingRef = useRef<NodeJS.Timer>()
  const [ppId, setPhilosophyPackId] = useState(null)
  const [videoId, setvideoId] = useState(null)

  const constructWsUrl = (page: string, userid: string) => {
    const wsURL = `${WS_URL}?pageType=${pageType}`
    let additionalParams = ''
    if (pageType === PageType.TRICODER) {
      additionalParams = `&userId=${userId}`
    } else if ([PageType.PHILOSOPHYPACKTRICORDER, PageType.PHILOSOPHYPACK].includes(pageType)) {
      const DEVICE_TYPE = getDeviceType()
      const wsToken = localStorage.getItem('jwt')
      additionalParams = `&token=${wsToken}&deviceType=${DEVICE_TYPE}`
    }
    return wsURL + additionalParams
  }

  useEffect(() => {
    if (!userId) return
    const startConnection = () => {
      wsRef.current = new W3CWebSocket(constructWsUrl(pageType, userId))

      wsRef.current.onopen = () => {
        console.log('Web Socket Opened:', new Date().toISOString())
        startHeartbeat()
      }

      wsRef.current.onerror = (error) => {
        startRetryPolling()
      }

      wsRef.current.onmessage = (mssg) => {
        const data = JSON.parse(mssg.data)
        const { eventName, message } = data
        if (eventName === WS_EVENT.SURFING_CONTENT_CHANGE) {
          if (message.ssid && message?.ssid !== ssid) setSsid(message.ssid)
        }
        if (eventName === WS_EVENT.PHILOSOPHY_PACK_CONTENT_CHANGE) {
          if (message.philosophyPackId && message?.videoId) setvideoId(message.videoId)
        }
        onMessage?.(mssg)
      }

      wsRef.current.onclose = (event) => {
        console.log('Web Socket Closed:', new Date().toISOString(), event)
        stopHeartbeat()
        if (router.pathname === ROUTES.Tricorder) {
          startConnection()
        }
      }
    }
    startConnection()

    function startRetryPolling() {
      if (pollingRef.current) {
        clearInterval(pollingRef.current)
      }
      pollingRef.current = setInterval(startConnection, RETRY_CONNECTION_POLLING_FREQUENCY)
    }
    // Cleanup function for component unmount or when dependencies change
    return () => {
      stopRetryPolling()
      stopHeartbeat()
      if (wsRef.current && wsRef.current.readyState === W3CWebSocket.OPEN) {
        wsRef.current.close()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, pageType, router.pathname, onMessage])

  // Web Sockets has an idle timeout of 60 seconds
  // so we implement a heartbeat mechanism where
  // we ping the server every 10 seconds to keep
  // the connection alive
  const startHeartbeat = () => {
    pingIntervalRef.current = setInterval(() => {
      if (wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send('ping')
      }
    }, 10000) // 10 seconds
  }
  function stopRetryPolling() {
    clearInterval(pollingRef.current)
  }
  function stopHeartbeat() {
    clearInterval(pingIntervalRef.current)
  }

  const mediaEventFunction = (data) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(
        JSON.stringify({
          event: WS_EVENT.TRICORDER_PAGE_STATUS,
          data,
        }),
      )
      console.log(`Event: ${WS_EVENT.TRICORDER_PAGE_STATUS} sent:`, new Date().toISOString())
    } else {
      console.log('WebSocket not open. Event not sent.')
    }
  }

  const TricoderPageCloseEvent = (data) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(
        JSON.stringify({
          event: WS_EVENT.PHILOSOPHY_PACK_PAGE_STATUS,
          data,
        }),
      )
    } else {
      console.log('WebSocket not open. Event not sent.')
    }
  }

  const philosophyPackContentChangeEventSend = (
    userid: string,
    philosophyPackId: string,
    videoid: string,
  ) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(
        JSON.stringify({
          event: WS_EVENT.PHILOSOPHY_PACK_CONTENT_CHANGE,
          data: {
            userId: userid,
            PageType: PageType.PHILOSOPHYPACK,
            videoId: videoid,
            philosophyPackId,
          },
        }),
      )
    } else {
      console.log('WebSocket not open. Event not sent.')
    }
  }

  return {
    pageType,
    setPageType,
    setUserId,
    setSsid,
    ssid,
    mediaEventFunction,
    ppId,
    setPhilosophyPackId,
    videoId,
    setvideoId,
    TricoderPageCloseEvent,
    philosophyPackContentChangeEventSend,
  }
}
