export type Inflation = {
  id?: string
  title: string
  referenceURL: string
  description: string
  default?: boolean
  usOfficial?: boolean
  status?: InflationStatus
  userId?: string
  user?: User
  yearlyInflations?: YearlyInflation[]
  createdAt?: Date
  deletedAt?: Date
  updatedAt?: Date
}

export type YearlyInflation = {
  year: number
  inflationPercentage: number
}

export enum InflationStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Declined = 'Declined',
}

export type User = {
  username: string
}

export type InflationGetAllResponse = {
  data: Inflation[]
  count: number
  page: number
  size: number
}

export type InflationPageParams = {
  page: number
  limit: number
  inflationStatus?: InflationStatus
}
