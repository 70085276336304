import { getAxios } from '@api/api'
import {
  Inflation,
  InflationGetAllResponse,
  InflationPageParams,
  InflationStatus,
} from '@api/Inflation'
import { AddResponse } from '@type/common'

const ALL_INFLATION_API_PATH = '/worker-defense-dept/inflation'

const US_OFFICIAL_INFLATION_API_PATH = '/worker-defense-dept/inflation-us-official'

const DEFAULT_INFLATION_API_PATH = '/worker-defense-dept/inflation-default'

const INFLATION_BY_ID_API_PATH = '/worker-defense-dept/inflation/'

const INFLATION_WITH_YEARLY_SAVE_PATH = '/worker-defense-dept/inflation/with-yearly'

const INFLATION_WITH_YEARLY_UPDATE_PATH = '/worker-defense-dept/inflation/with-yearly/:id'

const INFLATION_STATUS_UPDATE_PATH = '/worker-defense-dept/inflation/:id/status'

export const getAppInflationApi = async (
  params: InflationPageParams,
): Promise<InflationGetAllResponse> => {
  const { data } = await getAxios().get(ALL_INFLATION_API_PATH, {
    params,
  })
  return data
}

export const getDefaultInflationApi = async (): Promise<Inflation> => {
  const { data } = await getAxios().get(DEFAULT_INFLATION_API_PATH, {})
  return data
}

export const getUsOfficialInflationApi = async (): Promise<Inflation> => {
  const { data } = await getAxios().get(US_OFFICIAL_INFLATION_API_PATH, {})
  return data
}

export const getInflationByIdApi = async (id): Promise<Inflation> => {
  const { data } = await getAxios().get(INFLATION_BY_ID_API_PATH + id)
  return data
}

export const addInflationWithYearlyApi = async (inflationWithYearlyData: Inflation) => {
  const { data } = await getAxios().post<AddResponse<Inflation>>(
    INFLATION_WITH_YEARLY_SAVE_PATH,
    inflationWithYearlyData,
  )
  return data
}

export const updateInflationWithYearlyApi = async ({
  inflationWithYearlyData,
  id,
}: {
  inflationWithYearlyData: Inflation
  id: string
}) => {
  const { data } = await getAxios().put<AddResponse<Inflation>>(
    INFLATION_WITH_YEARLY_UPDATE_PATH.replace(':id', id),
    inflationWithYearlyData,
  )
  return data
}

export const updateInflationStatusApi = async ({
  id,
  status,
}: {
  id: string
  status: InflationStatus
}) => {
  const { data } = await getAxios().patch<AddResponse<Inflation>>(
    INFLATION_STATUS_UPDATE_PATH.replace(':id', id),
    { status },
  )
  return data
}

export const deleteInflationApi = async (id: string): Promise<Inflation> => {
  const { data } = await getAxios().delete(INFLATION_BY_ID_API_PATH + id)
  return data
}
