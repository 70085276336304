import styled from '@emotion/styled'
import { Box, MenuItem, Typography, Link, Button } from '@mui/material'
import Image from 'next/image'
import theme from '@theme'
import { SettingsIcon } from '@commons/modules/Icons/icons'
import downloadPageBackground from '@asset/game/registration/bg_registration.webp'

export const BoxMrkHeaderMainWrapper = styled(Box)(() => ({
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}))

export const BoxMrkHeaderMenuItemWrapper = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  gap: '2.5vw',
  flexDirection: 'row',
  justifyContent: 'end',
  textAlign: 'center',
  alignItems: 'center',
  backgroundColor: '#263049',
  padding: '20px',
  clipPath: 'polygon(0 0, 100% 0, 100% 100%, 6% 100%)',
  '@media (max-width: 991px)': {
    display: 'none',
  },
}))
export const TypographyMenuItemStyle = styled(Typography)(() => ({
  color: theme.palette.grey5,
  fontSize: '18px',
  fontWeight: '500',
  cursor: 'pointer',
  ':hover': {
    color: theme.palette.purple1,
  },
  textTransform: 'none',
}))

export const TypographyMenuOutSideItemStyle = styled(Typography)(() => ({
  color: theme.palette.neutralVariant100,
  fontSize: '16px',
  fontWeight: '800',
  cursor: 'pointer',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  ':hover': {
    color: theme.palette.purple1,
  },
  textTransform: 'uppercase',
  marginLeft: '20px',
}))

export const BoxMrkHeaderLoginItemWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '.5vw',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledSettingsIcon = styled(SettingsIcon)(() => ({
  color: theme.palette.grey4,
  fontSize: '1.2vw',
  fontWeight: 700,
  cursor: 'pointer',
  ':hover': {
    color: theme.palette.neutralVariant100,
  },
}))

export const OthersDownloadContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.up('lg')]: {
    flexWrap: 'unset',
  },
}))

export const OthersDownloadLink = styled(Link)(() => ({
  maxWidth: '700px',
  width: 'fit-content',
}))

export const OthersDownloadImage = styled(Image)(() => ({
  marginRight: '20px',
  marginBottom: '20px',
  height: 'unset',
  width: '130px',
  objectFit: 'contain',
  objectPosition: 'left',
  flexShrink: 0,
}))

export const HeroImageImg = styled(Image)(() => ({
  aspectRatio: '4/5',
  height: '100%',
  width: '100%',
  '@media (max-width: 991px)': {
    height: '50%',
  },
}))

export const HeroImageIcon = styled(Image)(() => ({
  height: '66px',
  marginTop: '10px',
  width: '280px',
  objectFit: 'contain',
  objectPosition: 'center',
  [theme.breakpoints.up('sm')]: {
    objectPosition: 'left',
  },
}))

export const MenuItemWrapper = styled(Box)(() => ({
  maxWidth: '40px',
  color: theme.palette.neutralVariant100,
  display: 'none',
  visibility: 'hidden',
  justifyContent: 'center',
  '@media (max-width: 991px)': {
    display: 'flex',
    visibility: 'visible',
    marginRight: '20px',
  },
}))

export const DropDownMenuItemWrapper = styled(Box)(() => ({
  maxWidth: '40px',
  color: theme.palette.neutralVariant100,
  display: 'flex',
  visibility: 'visible',
  justifyContent: 'center',
  '@media (max-width: 991px)': {
    display: 'none',
    visibility: 'hidden',
  },
}))

export const TypographyMinScreenMenuItemStyle = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 'bold',
  cursor: 'pointer',
  ':hover': {
    color: theme.palette.neutralVariant100,
  },
  textTransform: 'none',
  color: `${theme.palette.black1} !important`,
  display: 'none',
  '@media (max-width: 991px)': {
    display: 'flex',
  },
  width: '100%',
}))

export const MenuItemWrapperForSmallScreen = styled(MenuItem)(() => ({
  display: 'none',
  '@media (max-width: 991px)': {
    display: 'flex',
  },
}))

export const BoxDonwloadMainWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundImage: `url(${downloadPageBackground.src})`,
  height: '100vh',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center top',
  userSelect: 'none',
}))

export const BoxDonwloadSubWrapper = styled(Box)(() => ({
  maxHeight: '100vh',
  overflow: 'auto',
  marginBottom: '20px',
  marginTop: '2%',
  [theme.breakpoints.up('xl')]: {
    marginTop: '5%',
  },
  [theme.breakpoints.up('xxl')]: {
    marginTop: '8%',
  },
  alignItems: 'center',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
}))

export const BoxDonwloadMainContentWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '50px',
  alignItems: 'center',
  width: '100%',
}))

export const BoxDonwloadTitleContentWrapper = styled(Box)(() => ({
  display: 'flex',
  padding: '24px 20px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '12px',
  borderRadius: '10px',
  background: `linear-gradient(90deg, rgba(38, 48, 73, 0.00) 0%, ${theme.palette.blue15} 50%, rgba(38, 48, 73, 0.00) 100%)`,
  width: '70%',
}))

export const TypographyDownloadTitle = styled(Typography)(() => ({
  color: theme.palette.neutralVariant100,
  fontSize: '48px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  letterSpacing: '-0.96px',
  textTransform: 'uppercase',
  textAlign: 'center',
}))

export const TypographyDownloadSubTitle = styled(Typography)(() => ({
  color: theme.palette.neutralVariant100,
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
  textAlign: 'center',
}))

export const ButtonDonwloadLunchGame = styled(Button)(() => ({
  borderRadius: '12px',
  padding: ' 20px 24px',
  background: theme.palette.purple1,
  boxShadow: `0px 2px 3px 0px rgba(38, 48, 73, 0.30)`,
  color: theme.palette.neutralVariant100,
  leadingTrim: 'both',
  fontSize: '2vh',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  textAlign: 'center',
  textTransform: 'uppercase',
  '&:hover': {
    background: theme.palette.purple,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8vh',
    padding: '12px 20px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.5vh',
    padding: '14px 22px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2vh',
    padding: '20px 24px',
  },
}))

export const TypographyDownloadSubTitleOfButtom = styled(Typography)(() => ({
  color: theme.palette.purple,
  textAlign: 'center',
  fontSize: '1.5vh',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  letterSpacing: ' 0.5px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2vh',
  },
}))

export const BoxDownloadPageButtomGroupMainWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '50px',
}))

export const BoxDownloadPageGridItemWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'center',
}))

export const LinkDownloadGroups = styled(Link)(() => ({
  borderRadius: '12px',
  background: theme.palette.purple,
  boxShadow: `0px 2px 3px 0px rgba(38, 48, 73, 0.30)`,
  display: 'flex',
  color: theme.palette.neutralVariant100,
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  padding: '16px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  maxWidth: 'fit-content',
  textTransform: 'none',
  minWidth: '300px',
  textDecoration: 'none',
  cursor: 'pointer',
  ':hover': {
    background: theme.palette.purple1,
  },
}))

export const LinkButtonLevelText = styled(Link)(() => ({
  color: theme.palette.purple,
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  letterSpacing: '0.5px',
  textDecorationLine: 'underline',
  textDecorationColor: theme.palette.purple,
}))

export const BoxButtonGroupSubWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '30px',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}))

export const BoxHeaderMenuItemMainWrapper = styled(Box)(() => ({
  backgroundColor: theme.palette.purple1,
  paddingLeft: '10px',
  clipPath: 'polygon(0 0, 100% 0, 100% 100%, 6% 100%)',
  alignSelf: 'baseline',
}))
