/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import isEmpty from 'lodash/isEmpty'

import { Box, Button, CircularProgress, Menu, MenuItem } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import UserIcon from '@mui/icons-material/AccountCircleOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'

import { endtheRigConfig } from 'configs/endtherig'
import useCurrentUser from '@hook/user/useCurrentUser'
import theme from '@theme'
import {
  AdminMain,
  Download,
  Home,
  Leaderboard,
  Payment,
  Plans,
  SettingBillingPlans,
  SignIn,
  SignUp,
  StoryEngine,
} from '@constant/Routes/Routes'

import ROUTES from '@constant/Routes'
import useLogout from '@hook/user/useLogout'
import UserAvatar from '@commons/modules/UserAvatar/UserAvatar'
import { getAssetURI } from '@util/helpers'
import { reqLogoutUser } from '@api/api'
import { PROJECT } from 'configs'
import LegalLinks from '@constant/LegalLinks'
import { StyleLogoImage } from '../Quest/QuestPlaylanding.Styles'
import {
  BoxHeaderMenuItemMainWrapper,
  BoxMrkHeaderMainWrapper,
  BoxMrkHeaderMenuItemWrapper,
  DropDownMenuItemWrapper,
  MenuItemWrapper,
  MenuItemWrapperForSmallScreen,
  TypographyMenuItemStyle,
  TypographyMenuOutSideItemStyle,
  TypographyMinScreenMenuItemStyle,
} from './Style'

const MarketingPageHeader = () => {
  const { currentUser, isAdmin, isLoading } = useCurrentUser()
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(null)
  const [anchorElMobile, setAnchorElMobile] = useState(null)
  const [openMobile, setOpenMobile] = useState(false)
  const router = useRouter()
  const { logoutUser } = useLogout()
  const [activeMenuItem, setActiveMenuItem] = useState('')

  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget)
    setOpen(value) // Toggle the open state
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpen(null)
  }

  const handleClickMobile = (event) => {
    setAnchorElMobile(event.currentTarget)
    setOpenMobile(!openMobile) // Toggle the open state
  }
  const handleCloseMobile = () => {
    setAnchorElMobile(null)
    setOpenMobile(false)
  }

  const handleHeaderLogoClick = () => {
    router.push(Home)
  }

  const menuItems = [Download, Leaderboard, Payment, AdminMain, StoryEngine, SignIn, Home]
  useEffect(() => {
    const activeItem = menuItems.includes(router.route) ? router.route : Home
    setActiveMenuItem(activeItem)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  const handlePlansClick = () => {
    currentUser ? router.push(SettingBillingPlans) : router.push(Plans)
  }

  const handleMenuItemClick = (route) => {
    setActiveMenuItem(route)
    router.push(route)
  }

  const renderUserMenu = () => {
    if (!isEmpty(currentUser)) {
      return (
        <DropDownMenuItemWrapper>
          <Button
            id="user-button"
            aria-controls={open || undefined}
            aria-haspopup="true"
            aria-expanded={open === 'user-menu' ? 'true' : undefined}
            onClick={(e) => handleClick(e, 'user-menu')}
          >
            <TypographyMenuItemStyle sx={{ display: 'flex', alignItems: 'center' }}>
              {currentUser.profilePicture ? (
                <UserAvatar src={getAssetURI(currentUser.profilePicture)} height={25} width={25} />
              ) : (
                <UserIcon />
              )}
              {open === 'user-menu' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </TypographyMenuItemStyle>
          </Button>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open === 'user-menu'}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'user-button',
            }}
          >
            <MenuItem>
              <TypographyMenuItemStyle
                sx={{
                  color: `${theme.palette.black1} ! important`,
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => router.push(ROUTES.Settings)}
              >
                <SettingsOutlinedIcon sx={{ mr: 1 }} />
                Settings
              </TypographyMenuItemStyle>
            </MenuItem>
            <MenuItem>
              <TypographyMenuItemStyle
                sx={{
                  color: `${theme.palette.black1} ! important`,
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={async () => {
                  const jwt = localStorage.getItem('jwt')
                  await reqLogoutUser(jwt)
                  await logoutUser()
                }}
              >
                <LogoutOutlinedIcon sx={{ mr: 1 }} />
                Logout
              </TypographyMenuItemStyle>
            </MenuItem>
          </Menu>
        </DropDownMenuItemWrapper>
      )
    }
    if (isLoading) {
      return <CircularProgress size={30} />
    }

    return (
      <TypographyMenuOutSideItemStyle
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => handleMenuItemClick(router.pathname === SignIn ? SignUp : SignIn)}
      >
        {router.pathname === SignIn ? 'SignUp' : 'Login'}
      </TypographyMenuOutSideItemStyle>
    )
  }

  return (
    <BoxMrkHeaderMainWrapper>
      <Box className="header-logo" sx={{ cursor: 'pointer' }} onClick={handleHeaderLogoClick}>
        <StyleLogoImage height={100} width={100} src={endtheRigConfig.logo} alt="HOE Icon" />
      </Box>
      <BoxHeaderMenuItemMainWrapper>
        <BoxMrkHeaderMenuItemWrapper>
          {!isEmpty(currentUser) && (
            <TypographyMenuOutSideItemStyle onClick={() => handleMenuItemClick(Leaderboard)}>
              Leaderboard
            </TypographyMenuOutSideItemStyle>
          )}

          {/* <TypographyMenuOutSideItemStyle onClick={() => handleMenuItemClick(About)}>
            About
          </TypographyMenuOutSideItemStyle> */}

          <TypographyMenuOutSideItemStyle onClick={handlePlansClick}>
            Plans
          </TypographyMenuOutSideItemStyle>
          <TypographyMenuOutSideItemStyle
            onClick={() => handleMenuItemClick(Download)}
            sx={{
              color:
                activeMenuItem === '/download'
                  ? `${theme.palette.purple1} !important`
                  : `${theme.palette.neutralVariant100}`,
            }}
          >
            Download
          </TypographyMenuOutSideItemStyle>
          <DropDownMenuItemWrapper>
            <Button
              id="basic-button"
              aria-controls={open || undefined}
              aria-haspopup="true"
              aria-expanded={open === 'basic-menu' ? 'true' : undefined}
              onClick={(e) => handleClick(e, 'basic-menu')}
            >
              <TypographyMenuOutSideItemStyle sx={{ display: 'flex' }}>
                More {open === 'basic-menu' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </TypographyMenuOutSideItemStyle>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open === 'basic-menu'}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {/* <MenuItem
              onClick={() => {
                window.open(LegalLinks.AboutUs, '_blank')
              }}
            >
              <TypographyMenuItemStyle sx={{ color: `${theme.palette.black1} ! important` }}>
                About Us
              </TypographyMenuItemStyle>
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.open(LegalLinks.TermsAndConditions, '_blank')
              }}
            >
              <TypographyMenuItemStyle sx={{ color: `${theme.palette.black1} ! important` }}>
                Terms & Services
              </TypographyMenuItemStyle>
            </MenuItem> */}

              {!isEmpty(currentUser) && !isAdmin && (
                <MenuItem
                  onClick={() => {
                    window.open(
                      `${process.env.NEXT_PUBLIC_RIGGED_ECONOMY_URL}${ROUTES.InternetAppsPhilosophicPack}`,
                      '_blank',
                    )
                  }}
                >
                  <TypographyMenuItemStyle sx={{ color: `${theme.palette.black1} ! important` }}>
                    Rigged Economy
                  </TypographyMenuItemStyle>
                </MenuItem>
              )}
              {!isEmpty(currentUser) && isAdmin && (
                <MenuItem onClick={() => handleMenuItemClick(AdminMain)}>
                  <TypographyMenuItemStyle sx={{ color: `${theme.palette.black1} ! important` }}>
                    Admin
                  </TypographyMenuItemStyle>
                </MenuItem>
              )}

              <MenuItem
                onClick={() => {
                  window.open(`${PROJECT.privacyPolicy}`, '_blank')
                }}
              >
                <TypographyMenuItemStyle sx={{ color: `${theme.palette.black1} ! important` }}>
                  Privacy Policy
                </TypographyMenuItemStyle>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.open(LegalLinks.TermsAndServices, '_blank')
                }}
              >
                <TypographyMenuItemStyle sx={{ color: `${theme.palette.black1} ! important` }}>
                  Terms & Services
                </TypographyMenuItemStyle>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.open(LegalLinks.Wiki_End_The_Rig, '_blank')
                }}
              >
                <TypographyMenuItemStyle sx={{ color: `${theme.palette.black1} ! important` }}>
                  Wiki For End the Rig
                </TypographyMenuItemStyle>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.open(LegalLinks.CommunityPolicy, '_blank')
                }}
              >
                <TypographyMenuItemStyle sx={{ color: `${theme.palette.black1} ! important` }}>
                  Community Policy
                </TypographyMenuItemStyle>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  window.open(LegalLinks.Launch_Article_Gitbook, '_blank')
                }}
              >
                <TypographyMenuItemStyle sx={{ color: `${theme.palette.black1} ! important` }}>
                  Launch Article & Gitbook
                </TypographyMenuItemStyle>
              </MenuItem>
            </Menu>
          </DropDownMenuItemWrapper>
          {renderUserMenu()}
        </BoxMrkHeaderMenuItemWrapper>
      </BoxHeaderMenuItemMainWrapper>

      <MenuItemWrapper>
        <Button
          id="basic-button-mobile"
          aria-controls={openMobile ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMobile ? 'true' : undefined}
          onClick={handleClickMobile}
        >
          <TypographyMenuItemStyle sx={{ display: 'flex' }}>
            <MenuIcon fontSize="large" />
          </TypographyMenuItemStyle>
        </Button>
        <Menu
          id="basic-menu-mobile"
          anchorEl={anchorElMobile}
          open={openMobile}
          onClose={handleCloseMobile}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {currentUser && (
            <>
              {isAdmin && (
                <MenuItemWrapperForSmallScreen>
                  <TypographyMinScreenMenuItemStyle
                    sx={{ color: `${theme.palette.black1} ! important` }}
                    onClick={() => handleMenuItemClick(AdminMain)}
                  >
                    Admin
                  </TypographyMinScreenMenuItemStyle>
                </MenuItemWrapperForSmallScreen>
              )}

              <MenuItemWrapperForSmallScreen>
                <TypographyMinScreenMenuItemStyle
                  onClick={() => handleMenuItemClick(SettingBillingPlans)}
                >
                  Plans
                </TypographyMinScreenMenuItemStyle>
              </MenuItemWrapperForSmallScreen>

              <MenuItemWrapperForSmallScreen>
                <TypographyMinScreenMenuItemStyle onClick={() => handleMenuItemClick(Leaderboard)}>
                  Leaderboard
                </TypographyMinScreenMenuItemStyle>
              </MenuItemWrapperForSmallScreen>
            </>
          )}

          {/* <MenuItemWrapperForSmallScreen>
            <TypographyMinScreenMenuItemStyle>About Us</TypographyMinScreenMenuItemStyle>
          </MenuItemWrapperForSmallScreen> */}

          <MenuItemWrapperForSmallScreen>
            <TypographyMinScreenMenuItemStyle onClick={() => handleMenuItemClick(Download)}>
              Download
            </TypographyMinScreenMenuItemStyle>
          </MenuItemWrapperForSmallScreen>

          <MenuItemWrapperForSmallScreen>
            <TypographyMinScreenMenuItemStyle
              onClick={() => {
                window.open(`${PROJECT.privacyPolicy}`, '_blank')
              }}
            >
              Privacy Policy
            </TypographyMinScreenMenuItemStyle>
          </MenuItemWrapperForSmallScreen>

          <MenuItemWrapperForSmallScreen>
            <TypographyMinScreenMenuItemStyle
              onClick={() => {
                window.open(LegalLinks.TermsAndServices, '_blank')
              }}
            >
              Terms & Services
            </TypographyMinScreenMenuItemStyle>
          </MenuItemWrapperForSmallScreen>

          <MenuItemWrapperForSmallScreen>
            <TypographyMinScreenMenuItemStyle
              onClick={() => {
                window.open(LegalLinks.CommunityPolicy, '_blank')
              }}
            >
              Community Policy
            </TypographyMinScreenMenuItemStyle>
          </MenuItemWrapperForSmallScreen>

          <MenuItemWrapperForSmallScreen>
            <TypographyMinScreenMenuItemStyle
              onClick={() => {
                window.open(LegalLinks.Wiki_End_The_Rig, '_blank')
              }}
            >
              Wiki For End the Rig
            </TypographyMinScreenMenuItemStyle>
          </MenuItemWrapperForSmallScreen>

          <MenuItemWrapperForSmallScreen>
            <TypographyMinScreenMenuItemStyle
              onClick={() => {
                window.open(LegalLinks.Launch_Article_Gitbook, '_blank')
              }}
            >
              {' '}
              Launch Article & Gitbook
            </TypographyMinScreenMenuItemStyle>
          </MenuItemWrapperForSmallScreen>

          {isEmpty(currentUser) ? (
            <MenuItemWrapperForSmallScreen>
              <TypographyMinScreenMenuItemStyle onClick={() => handleMenuItemClick(SignIn)}>
                Login
              </TypographyMinScreenMenuItemStyle>
            </MenuItemWrapperForSmallScreen>
          ) : (
            <MenuItem>
              <TypographyMinScreenMenuItemStyle
                onClick={async () => {
                  const jwt = localStorage.getItem('jwt')
                  await reqLogoutUser(jwt)
                  await logoutUser()
                }}
              >
                Logout
              </TypographyMinScreenMenuItemStyle>
            </MenuItem>
          )}
        </Menu>
      </MenuItemWrapper>
    </BoxMrkHeaderMainWrapper>
  )
}
export default MarketingPageHeader
