import Box from '@mui/material/Box'
import { useEffect } from 'react'
import NetworkStatus from '@commons/modules/NetworkStatus/Index'
import { Download } from '@constant/Routes/Routes'
import { useWebSocket } from '@hook/game/useWebSocket'
import useGamePlay from '@hook/game/useGamePlay'
import { WS_EVENT } from '@constant/index'
import useCurrentUser from '@hook/user/useCurrentUser'
import ROUTES from '@constant/Routes'

type Props = {
  children: React.ReactNode
}

const BoxSx = {
  bgcolor: 'background.page',
  width: '100%',
  minHeight: '100vh',
  p: 0,
}
// eslint-disable-next-line arrow-body-style
const MarketingDashboardLayout = ({ children }: Props) => {
  const { currentUser } = useCurrentUser()
  const { setGamePlayState } = useGamePlay()

  useWebSocket(currentUser?.id, null, ({ eventName }) => {
    if (eventName === WS_EVENT.GAME_OPENED) {
      setGamePlayState(null)
    }
  })

  useEffect(() => {
    const isDownloadPage = window.location.pathname === Download
    if (
      isDownloadPage ||
      window.location.pathname === '/' ||
      window.location.pathname === ROUTES.Home
    ) {
      document.body.style.overflowX = 'hidden'
      document.body.style.overflowY = 'auto'
    } else if (ROUTES.Tricorder) {
      document.body.style.overflow = 'visible'
    } else {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [])
  return (
    <Box className="page-container" component="main" sx={BoxSx}>
      <NetworkStatus />
      {children}
    </Box>
  )
}
export default MarketingDashboardLayout
