/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { Box, Button, Typography } from '@mui/material'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import Link from 'next/link'

import useCurrentUser from '@hook/user/useCurrentUser'
import { checkUserIsValid, UserValidResponse } from '@api/admin/user'
import { getSurfingSectionVideoInfo, SurfingSection } from '@api/surfing-section.api'
import { surfingCheckConnection } from '@api/surfing.api'
import { useWebSocket } from '@hook/tricoder/useWebSocket'
import ROUTES from '@constant/Routes'

import { PageType } from '@template/GameDashboard/types'
import { getTiktokAndYoutubeVideo } from '@api/tiktok-and-youtube-video.api'
import MarketingDashboardLayout from '@layout/Default/MarketingDashboard'
import Layout from './layout'

Tricorder.getLayout = (page: React.ReactElement) => (
  <MarketingDashboardLayout>{page}</MarketingDashboardLayout>
)

export default function Tricorder() {
  const router = useRouter()
  const [currentUserId, setCurrentUserId] = useState(router.query.u as string)
  const [embedHtml, setEmbedHtml] = useState(null)
  const [isTimerTricorderPassed, setIsTimerTricorder] = useState(false)
  const { currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser()
  const tikTokScriptRef = useRef(null)

  const {
    pageType,
    setUserId,
    ssid,
    setSsid,
    mediaEventFunction,
    ppId,
    setPhilosophyPackId,
    videoId,
    setvideoId,
    setPageType,
    TricoderPageCloseEvent,
  } = useWebSocket()

  useEffect(() => {
    const { from, philosophyPackId, videoId: tricoderVideoId, u } = router.query || {}
    if (from === 'unreal' && philosophyPackId && tricoderVideoId && u) {
      const linkToCopy = `/tricorder?philosophyPackId=${philosophyPackId}&videoId=${tricoderVideoId}&u=${u}`

      try {
        const newWindow = window.open(linkToCopy, '_blank', 'width=420,height=785,top=50,left=0')
        if (newWindow) {
          window.close()
        } else {
          console.error('Failed to open new window. Please check your browser settings.')
        }
      } catch (error) {
        console.error('Error opening new window:', error)
      }
    }
  }, [router.query])

  // After a user has interacted with the tricoder page, prompt for confirmation before closing the tricoder page.
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      event.returnValue = ''
      TricoderPageCloseEvent({
        userId: currentUserId,
        close: true,
        ppId,
      })
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [currentUserId, TricoderPageCloseEvent, ppId])

  useEffect(() => {
    if (router.query.u) setCurrentUserId(router.query.u as string)
  }, [router.query.u, setCurrentUserId])

  useEffect(() => {
    if (!isEmpty(currentUser)) setCurrentUserId(currentUser.id)
  }, [currentUser, setCurrentUserId])

  useEffect(() => {
    if (router.query.ssid) setSsid(router.query.ssid as string)
  }, [router.query.ssid, setSsid])

  useEffect(() => {
    if (router.query.philosophyPackId) {
      setPhilosophyPackId(router.query.philosophyPackId as string)
      setPageType(PageType.PHILOSOPHYPACKTRICORDER)
    }
  }, [router.query.philosophyPackId, setPageType, setPhilosophyPackId])

  useEffect(() => {
    if (router.query.videoId) {
      setvideoId(router.query.videoId as string)
    }
  }, [router.query.videoId, setvideoId])

  const { data: userValidResp = {} as UserValidResponse, isLoading: isCheckingUserValidity } =
    useQuery(['check-user-valid-by-id', currentUserId], () => checkUserIsValid(currentUserId), {
      enabled: Boolean(currentUserId),
      onSuccess(resp) {
        if (resp?.valid) setUserId(currentUserId)
      },
      onError(err: AxiosError) {
        toast.error(get(err, 'response.data.message', 'User not valid. Try login again.'))
      },
    })

  const {
    data: surfingConnectionData,
    isLoading: isLoadingSurfingConnection,
    isFetching: isFetchingSurfingConnection,
  } = useQuery(
    ['check-surfing-connection'],
    () => surfingCheckConnection({ pageType: 'SURFING' }),
    {
      enabled: true,
      onError() {},
    },
  )
  const {
    data: philosophyPackvideosData,
    isLoading: isLoadingPhilosophyPackVideos,
    isFetching: isFetchingPhilosophyPackVideos,
  } = useQuery(
    ['tiktok-youtube-videos-tricorder', videoId],
    () => getTiktokAndYoutubeVideo(videoId),
    {
      enabled: Boolean(videoId),
      refetchOnMount: false,
    },
  )
  const {
    data: surfingSectionData = {} as SurfingSection,
    isLoading: isLoadingSurfingSection,
    isFetching: isFetchingSurfingSection,
  } = useQuery(
    ['get-surfing-section-by-id', ssid],
    () => getSurfingSectionVideoInfo(ssid, { userId: currentUserId }),
    {
      // enabled: Boolean(ssid) && Boolean(currentUserId) && (userValidResp.valid || false) && (surfingConnectionData?.connections !== 0),
      enabled: Boolean(ssid) && Boolean(currentUserId) && userValidResp.valid,
      onError(err: AxiosError) {
        toast.error(get(err, 'response.data.message', 'Error while fetching data.'))
      },
    },
  )

  useEffect(() => {
    if (!isEmpty(surfingSectionData) && userValidResp?.valid) {
      const base64EncodedUrl = surfingSectionData?.tricoderVideoEmbedUrl
      if (base64EncodedUrl) {
        const decodedUrl = atob(base64EncodedUrl)
        const modifiedContent = decodedUrl.replace(
          /style=(".*?")/g,
          'style="maxWidth: 605px; minWidth: 325px"',
        )
        setEmbedHtml(modifiedContent)
      }
    }
    // When the component is mounted, loading the TikTok script dynamically
    if (tikTokScriptRef.current) {
      const script = document.createElement('script')
      script.src = 'https://www.tiktok.com/embed.js'
      script.async = true
      tikTokScriptRef.current.appendChild(script)
    }
  }, [surfingSectionData, userValidResp?.valid])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isTimerTricorderPassed && !isEmpty(surfingSectionData) && userValidResp?.valid) {
        mediaEventFunction({
          userId: currentUserId,
          completed: true,
        })
        setIsTimerTricorder(true)
      }
    }, 15000) // 15000 milliseconds is equal to 15 seconds

    return () => {
      if (isTimerTricorderPassed) clearTimeout(timer)
    }
  }, [
    currentUserId,
    surfingSectionData,
    userValidResp?.valid,
    isTimerTricorderPassed,
    mediaEventFunction,
  ])

  useEffect(() => {
    if (!isEmpty(philosophyPackvideosData) && userValidResp?.valid) {
      if (tikTokScriptRef.current) {
        const script = document.createElement('script')
        script.src = 'https://www.tiktok.com/embed.js'
        script.async = true
        tikTokScriptRef.current.appendChild(script)
      }
    }
  }, [philosophyPackvideosData, userValidResp?.valid])

  if (!currentUserId || isCheckingUserValidity || !userValidResp?.valid)
    return (
      <Layout
        loading={isLoadingCurrentUser || isCheckingUserValidity}
        loadingMsg="Checking User Validation..."
      >
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h4" color="grey">
            You seem to be logged out. Try logging in again.
          </Typography>
          <Button
            onClick={() => {
              if (pageType === PageType.PHILOSOPHYPACKTRICORDER) {
                router.push(
                  `${ROUTES.SignIn}?redirectTo=${router.pathname}?philosophyPackId=${router.query.philosophyPackId}&videoId=${videoId}&u=${router.query.u}`,
                )
              } else {
                router.push(
                  `${ROUTES.SignIn}?redirectTo=${router.pathname}?ssid=${router.query.ssid}&u=${router.query.u}`,
                )
              }
            }}
            variant="contained"
            size="large"
            sx={{ mt: 2 }}
          >
            Log In
          </Button>
        </Box>
      </Layout>
    )

  if (ppId) {
    return (
      <Layout
        loading={isLoadingPhilosophyPackVideos || isFetchingPhilosophyPackVideos}
        loadingMsg="Checking Connection..."
      >
        {!isLoadingPhilosophyPackVideos && philosophyPackvideosData && (
          <Box>
            <Box
              dangerouslySetInnerHTML={{
                __html: Buffer.from(philosophyPackvideosData.videoEmbedUrl, 'base64').toString(
                  'ascii',
                ),
              }}
            />
            <Box ref={tikTokScriptRef} />
          </Box>
        )}
      </Layout>
    )
  }
  if (isEmpty(surfingSectionData))
    // temporary disable until surfingconnection check is completed
    // if (surfingConnectionData?.connections === 0 || ieEmpty(surfingSectionData))
    return (
      <Layout
        loading={isLoadingSurfingConnection || isFetchingSurfingConnection}
        loadingMsg="Checking Surfing Connection"
      >
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h4" color="grey">
            Waiting for you to enter Surfing.
          </Typography>
          <Typography color="grey" sx={{ mt: 2 }}>
            To Enter Surfing;
            <ol>
              <li>
                Go to{' '}
                <Link href={ROUTES.GameDashBoard} target="_blank">
                  Game Dashboard
                </Link>
              </li>
              <li>
                From &quot;HUB&quot;, enter &quot;Surfing Section&quot; and pick a Major Area.
              </li>
              <li>
                Or, you can go from &quot;Underground&quot;, enter &quot;Surfing Section&quot; and
                pick a Major Area.
              </li>
            </ol>
          </Typography>
        </Box>
      </Layout>
    )

  return (
    <Layout
      loading={isLoadingSurfingSection || isFetchingSurfingSection}
      loadingMsg="Fetching Surfing Section Data..."
    >
      <Box>
        {/* TikTok embed code with dynamic content from API response */}
        {embedHtml && <div dangerouslySetInnerHTML={{ __html: embedHtml }} />}
        {/* Container for loading TikTok script dynamically */}
        <div ref={tikTokScriptRef} />
      </Box>
    </Layout>
  )
}
