import { Box, Button } from '@mui/material'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { Column } from 'react-table'
import Header from '@module/Header/Header'
import ROUTES from '@constant/Routes'

import List from '../../commons/List'
import withProductionCheck from '../../commons/with-production-check'

const columns: Array<Column> = [
  { Header: 'C-ID', accessor: 'id' },
  { Header: 'Title', accessor: 'title' },
  { Header: 'State', accessor: 'state' },
  { Header: 'City', accessor: 'city' },
  { Header: 'Date', accessor: 'date' },
]

const MedicalExampleTable: FC<void> = () => {
  const router = useRouter()
  const renderDynamicActionMenu = (row: any, refetchForumPost) => [
    {
      title: row?.values?.isCensored === 'No' ? 'Censor' : 'UnCensor',
      onClick: () => {},
    },
    {
      title: 'Edit',
      onClick: (id) => {},
    },
  ]
  return (
    <>
      <Box sx={{ ml: '5px' }}>
        <Header title="Examples" />
        <Button
          sx={{ ml: 1 }}
          variant="outlined"
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            router.push(ROUTES.MedicalAddExample)
          }}
        >
          + Add
        </Button>
      </Box>

      <List
        containerSx={{ mt: 4, pl: '20px', pr: '20px' }}
        columns={columns}
        title="List of Medical Examples"
        noDataText="No Forums Available."
        dataLabel="data"
        queryKey="meme-list"
        hasActionCol
        renderDynamicActionMenu={renderDynamicActionMenu}
      />
    </>
  )
}

export default withProductionCheck(MedicalExampleTable)
