import { DeviceType } from '@type/schemas/game-session'

export const getDeviceType = (): DeviceType => {
  const defaultDevice: DeviceType = DeviceType.OTHERS

  const { userAgent } = window.navigator

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return DeviceType.IPHONE
  }
  if (/android|Android/.test(userAgent)) {
    return DeviceType.ANDROID
  }
  if (/Win|Win32|Win64|Windows|WinCE/.test(userAgent)) {
    return DeviceType.WINDOWS
  }
  if (/Mac|Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
    return DeviceType.MAC
  }

  return defaultDevice
}
