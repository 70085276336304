import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import ROUTES from '@constant/Routes'
import { WS_EVENT, WS_URL } from '@constant/index'
import { UserGameInfo } from '@type/schemas/UserGnomeBuddy'
import { getDeviceType } from '@util/device'
import useCurrentUser from '@hook/user/useCurrentUser'
import { WebsocketData } from '@type/websocket'
import { getUserGameTokenFromLocalStorage } from '@util/helpers'

const GAME_TYPE = 'WEBAPP'

const W3CWebSocket = require('websocket').w3cwebsocket

const RETRY_CONNECTION_POLLING_FREQUENCY = 6e4

const GAME_ROUTES = [ROUTES.MyGame, ROUTES.GameDashBoard, ROUTES.UserGameInfo]

export const useWebSocket = (
  userId: string,
  onSubscribe?: () => void,
  onMessage?: (_: WebsocketData) => void,
) => {
  const queryClient = useQueryClient()
  const wsRef = useRef(null)
  const pingIntervalRef = useRef(null)
  const router = useRouter()
  const pollingRef = useRef<NodeJS.Timer>()
  const { refetch } = useCurrentUser()

  const [levelUpModalOpen, setLevelUpModalOpen] = useState(false)
  const [currentLevel, setCurrentLevel] = useState(0)

  useEffect(() => {
    const startConnection = () => {
      const DEVICE_TYPE = getDeviceType()
      const token = localStorage.getItem('jwt')
      let url = `${WS_URL}?&deviceType=${DEVICE_TYPE}&gameType=${GAME_TYPE}&userToken=${getUserGameTokenFromLocalStorage()}`
      if (token) {
        url += `&token=${token}`
      }
      wsRef.current = new W3CWebSocket(url)

      wsRef.current.onopen = () => {
        stopRetryPolling()
        startHeartbeat()
      }

      wsRef.current.onerror = () => {
        startRetryPolling()
      }

      wsRef.current.onmessage = (msg) => {
        stopRetryPolling()
        const data = JSON.parse(msg.data) as WebsocketData
        onMessage?.(data)
        const { eventName, message: newUserGameInfo } = data

        if (eventName === WS_EVENT.PLAYER_SUBSCRIPTION) {
          refetch()
          onSubscribe?.()
        }

        if (eventName === WS_EVENT.PLAYER_GAME_INFO) {
          const queryKey = ['user-game-info', userId]
          const oldUserGameInfo = queryClient.getQueryData<UserGameInfo>(queryKey)
          if (!oldUserGameInfo) return
          queryClient.invalidateQueries(queryKey)

          if (oldUserGameInfo.freeGnomes !== newUserGameInfo.freeGnomes) {
            queryClient.invalidateQueries(['user-engaged-gnomes', userId])
            queryClient.invalidateQueries(['value-engine', userId])
          }

          if (oldUserGameInfo.gameLevel !== newUserGameInfo.gameLevel) {
            setLevelUpModalOpen(true)
            setCurrentLevel(newUserGameInfo.gameLevel)
          }

          if (oldUserGameInfo.undergroundTeleporter !== newUserGameInfo.undergroundTeleporter) {
            queryClient.invalidateQueries(['under-ground-group', userId])
          }
        }

        if (eventName === WS_EVENT.PLAYER_ACTIVE_GEM_SLOTS) {
          queryClient.setQueryData(['user-gems-slot', userId], newUserGameInfo.data)
        }
      }

      wsRef.current.onclose = () => {
        stopHeartbeat()

        // Reconnect if on the "/game" page
        if (GAME_ROUTES.includes(router.pathname)) {
          startRetryPolling()
        } else {
          stopRetryPolling()
        }
      }
    }

    startConnection()

    function startRetryPolling() {
      if (pollingRef.current) {
        clearInterval(pollingRef.current)
      }
      pollingRef.current = setInterval(startConnection, RETRY_CONNECTION_POLLING_FREQUENCY)
    }

    return () => {
      stopRetryPolling()
      stopHeartbeat()
      // Check if wsRef.current is not null before attempting to close
      if (wsRef.current && wsRef.current.readyState === W3CWebSocket.OPEN) {
        wsRef.current.close()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onMessage])

  // Web Sockets has an idle timeout of 60 seconds
  // so we implement a heartbeat mechanism where
  // we ping the server every 10 seconds to keep
  // the connection alive
  const startHeartbeat = () => {
    pingIntervalRef.current = setInterval(() => {
      if (wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send('ping')
      }
    }, 10000) // 10 seconds
  }

  const stopRetryPolling = () => {
    clearInterval(pollingRef.current)
  }

  const stopHeartbeat = () => {
    clearInterval(pingIntervalRef.current)
  }

  return {
    levelUpModalOpen,
    setLevelUpModalOpen,
    currentLevel,
  }
}
