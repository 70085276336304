import { AddResponse, DeleteUpdateResponse, GetAllResponse, ListParams } from '@type/common'

import { EntityInfoCallback, TiktokAndYoutubeVideo } from '@type/tiktok-and-youtube-video'
import { API_URL } from '@constant'
import { getAxios } from './api'

type GetTiktokAndYoutubeVideoParams = {
  videoType: string
  published: boolean
}
export type TiktokAndYoutubeVideoQueryParams = ListParams & {
  exclude?: Array<string>
}
export const getAllTiktokAndYoutubeVideos = async (
  params: TiktokAndYoutubeVideoQueryParams,
): Promise<GetAllResponse<TiktokAndYoutubeVideo>> => {
  const { data } = await getAxios().get<GetAllResponse<TiktokAndYoutubeVideo>>(
    '/tiktok-and-youtube-videos',
    {
      params,
    },
  )
  return data
}

export const getTiktokAndYoutubeVideosByPhilPackId = async (
  params: ListParams,
  philosophyPackId: string,
): Promise<GetAllResponse<TiktokAndYoutubeVideo>> => {
  const { data } = await getAxios().get<GetAllResponse<TiktokAndYoutubeVideo>>(
    `/tiktok-and-youtube-videos/list/philosophy-pack/${philosophyPackId}`,
    {
      params,
    },
  )
  return data
}

export const getTiktokAndYoutubeVideosByRedboxId = async (
  params: ListParams,
  redboxId: string,
): Promise<GetAllResponse<TiktokAndYoutubeVideo>> => {
  const { data } = await getAxios().get<GetAllResponse<TiktokAndYoutubeVideo>>(
    `/tiktok-and-youtube-videos/list/red-box/${redboxId}`,
    {
      params,
    },
  )
  return data
}

export const getTiktokAndYoutubeVideosByPhilosophyPackIdForGame = async (
  id: string,
  params: GetTiktokAndYoutubeVideoParams,
) => {
  const { data } = await getAxios().get<GetAllResponse<TiktokAndYoutubeVideo>>(
    `/tiktok-and-youtube-videos/philosophy-pack/${id}`,
    { params },
  )
  return data
}

export const addTiktokAndYoutubeVideo = async ({
  values,
  entityInfoCallback,
}: {
  values: TiktokAndYoutubeVideo
  entityInfoCallback?: EntityInfoCallback
}) => {
  const payload = entityInfoCallback ? { ...values, entityInfoCallback } : values
  const { data } = await getAxios().post<AddResponse<TiktokAndYoutubeVideo>>(
    `/tiktok-and-youtube-videos`,
    payload,
  )
  return data
}

export const getTiktokAndYoutubeVideo = async (id: string) => {
  const { data } = await getAxios().get<TiktokAndYoutubeVideo>(`/tiktok-and-youtube-videos/${id}`)
  return data
}

export const editTiktokAndYoutubeVideo = async ({ values }: { values: TiktokAndYoutubeVideo }) => {
  const { data } = await getAxios().put<DeleteUpdateResponse>(
    `/tiktok-and-youtube-videos/${values.id}`,
    values,
  )
  return data
}

export const publishToggleTiktokAndYoutubeVideo = async ({
  id,
  published,
}: {
  id: string
  published: boolean
}) => {
  const { data } = await getAxios().patch(`${API_URL}/tiktok-and-youtube-videos/publish/${id}`, {
    published,
  })
  return data
}

export const deleteTiktokAndYoutubeVideo = async (id: string) => {
  const { data } = await getAxios().delete<DeleteUpdateResponse>(`tiktok-and-youtube-videos/${id}`)
  return data
}

export async function approveTiktokAndYoutubeVideo(id: string): Promise<any> {
  const { data } = await getAxios().patch(`${API_URL}/tiktok-and-youtube-videos/approve/${id}`)
  return data
}

export async function rejectTiktokAndYoutubeVideo(id: string): Promise<any> {
  const { data } = await getAxios().patch(`${API_URL}/tiktok-and-youtube-videos/reject/${id}`)
  return data
}
