import { Box } from '@mui/material'
import React from 'react'
import { IS_PRODUCTION } from '@constant'

const withProductionCheck = (WrappedComponent: React.FC<void>) => {
  const ProductionCheck = () => {
    const isProduction = IS_PRODUCTION
    // @ts-ignore
    return <Box>{isProduction ? <p>Coming soon...</p> : <WrappedComponent />}</Box>
  }

  return ProductionCheck
}

export default withProductionCheck
