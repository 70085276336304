/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import { Box, List, ListItem } from '@mui/material'
import { useRouter } from 'next/router'
import { useState } from 'react'
import qplbackgropund4 from '@asset/game/background_forth_section.webp'
import marketingCharacter from '@asset/game/marketingcharacter.webp'
import marketingCharacterfemale from '@asset/game/marketingcharacterfemale.webp'
import qplbackgropund1 from '@asset/game/background_first_section.webp'
import qplbackgropund2 from '@asset/game/background_second_section.webp'
import bgmarketing from '@asset/game/bgmarketing.webp'
import MarketingPageHeader from '@template/GameDashboard/Home/Header'
import {
  BoxqplInnerWrapper,
  BoxqplMainWrapper,
  BoxqplSubDivWrapper,
  BoxqplDivAdventureWrapper,
  BoxqplCenterContentMainWrapper,
  TypographyCenterTitleWrapper,
  BoxqplCenterCharacterWrapper,
  StyleImage,
  ButtonPlayNow,
  BoxCenterContentMainWrapper,
  TypographyCenterSubTitleWrapper,
  BoxqplMainBodyWrapper,
  StyleImagefemalecharacter,
  StyleLogoImagefooterlogo,
  DialogVideoPlayerMainWrapper,
  BoxVideoSubWrapper,
  CloseIconWprapper,
} from '@template/GameDashboard/Quest/QuestPlaylanding.Styles'
import { GTM_CUSTOM_TAG } from '@util/gtmTag'
import { pushToDataLayer } from '@util/gtm'
import useCurrentUser from '@hook/user/useCurrentUser'
import ROUTES from '@constant/Routes'
import { Home } from '@constant/Routes/Routes'
import { endtheRigConfig } from 'configs/endtherig'
import { PROJECT } from 'configs'
import LegalLinks from '@constant/LegalLinks'
import theme from '@theme'

const MarketingLandingPage = () => {
  const { currentUser } = useCurrentUser()
  const router = useRouter()
  const [open, setOpen] = useState(false)

  const handleOnPlayClick = () => {
    const beacon = document.getElementById('beacon-container')
    if (beacon) {
      beacon.style.display = 'none'
    }
    pushToDataLayer({
      event: GTM_CUSTOM_TAG.CLICK_PLAY_BUTTON,
      button_id: 'Play_Button',
      button_label: 'Play Now (Free)',
      timestamp: new Date().getTime(),
    })
    if (!currentUser) {
      const hasVisited = localStorage.getItem('has-visited')
      const redirectUrl = hasVisited ? ROUTES.SignIn : ROUTES.SignUp
      router.push(redirectUrl)
    } else {
      router.push(ROUTES.Download)
    }
  }

  const handleHeaderLogoClick = () => {
    router.push(Home)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // const CustomTextField = styled(TextField)({
  //   '& .MuiOutlinedInput-root': {
  //     '& fieldset': {
  //       borderColor: theme.palette.grey16,
  //       borderRadius: '10px',
  //     },
  //     '&:hover fieldset': {
  //       borderColor: theme.palette.grey16,
  //     },
  //     '&.Mui-focused fieldset': {
  //       borderColor: theme.palette.grey16,
  //     },
  //     '& .MuiInputBase-input::placeholder': {
  //       color: theme.palette.neutralVariant100,
  //     },
  //     '& .MuiInputBase-input': {
  //       color: theme.palette.neutralVariant100,
  //     },
  //   },
  // })

  return (
    <>
      {/* first section  */}
      <BoxqplMainWrapper sx={{ backgroundImage: `url(${bgmarketing.src}) !important` }}>
        <BoxqplInnerWrapper>
          <MarketingPageHeader />
          <BoxqplMainBodyWrapper>
            <BoxqplCenterContentMainWrapper>
              <BoxCenterContentMainWrapper
                sx={{
                  [theme.breakpoints.up('lg')]: {
                    '@media (orientation: portrait)': {
                      marginRight: '-5vh !important',
                    },
                  },
                }}
              >
                <TypographyCenterTitleWrapper>
                  YOU CAN FIX THE RIGGED ECONOMY
                </TypographyCenterTitleWrapper>
                <TypographyCenterSubTitleWrapper>
                  The playing field is uneven and everyday people are feeling the impact! We need
                  you to help inspire the spark of change to improve lives.
                </TypographyCenterSubTitleWrapper>
                <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <ButtonPlayNow onClick={handleOnPlayClick}>Play Now (Free)</ButtonPlayNow>
                  <ButtonPlayNow onClick={handleOpen}>Play Video</ButtonPlayNow>
                </Box>
              </BoxCenterContentMainWrapper>

              <BoxqplCenterCharacterWrapper>
                <StyleImage src={marketingCharacter} alt="marketing character" />
              </BoxqplCenterCharacterWrapper>
            </BoxqplCenterContentMainWrapper>
          </BoxqplMainBodyWrapper>
        </BoxqplInnerWrapper>
      </BoxqplMainWrapper>

      {/* Second section */}
      <BoxqplMainWrapper
        sx={{
          backgroundImage: `url(${qplbackgropund1.src}) !important`,
          flexWrap: 'wrap',
        }}
        className="qplMainWrapper"
      >
        <BoxqplSubDivWrapper sx={{ flexBasis: '100% !important' }}>
          <BoxCenterContentMainWrapper
            sx={{
              marginRight: 'auto',
            }}
          >
            <Box>
              <TypographyCenterTitleWrapper>Forge a New Destiny</TypographyCenterTitleWrapper>
            </Box>
            <Box>
              <TypographyCenterSubTitleWrapper>
                In End the Rig, you hold immense responsibility steering the nation away from
                corrupt politicians and dubious corporate agendas. As the nation’s architect, you
                investigate real-world issues caused by the malevolent forces manipulating the
                economy for their own personal gain. Your role is to uncover the truth and navigate
                the deceptive players along the journey, ensuring your actions lead to progress
                towards your ideal society.
              </TypographyCenterSubTitleWrapper>
            </Box>
            <Box display="flex">
              <ButtonPlayNow onClick={handleOnPlayClick}>Choose Your Path</ButtonPlayNow>
            </Box>
          </BoxCenterContentMainWrapper>
        </BoxqplSubDivWrapper>
      </BoxqplMainWrapper>

      {/* Third section */}
      <BoxqplMainWrapper
        sx={{ backgroundImage: `url(${qplbackgropund2.src}) !important` }}
        className="qplMainWrapper"
      >
        <BoxqplSubDivWrapper sx={{ justifyContent: 'space-evenly !important' }}>
          <BoxqplCenterCharacterWrapper>
            <StyleImagefemalecharacter src={marketingCharacterfemale} alt="marketing character" />
          </BoxqplCenterCharacterWrapper>

          <BoxCenterContentMainWrapper
            sx={{
              marginRight: '0px !important',
              [theme.breakpoints.up('lg')]: {
                '@media (orientation: portrait)': {
                  marginRight: '2vh !important',
                },
              },
            }}
          >
            <Box>
              <TypographyCenterTitleWrapper>A New Level Of Game-Play</TypographyCenterTitleWrapper>
            </Box>
            <Box>
              <TypographyCenterSubTitleWrapper>
                Every decision you make in the End the Rig affects the lives of everyday citizens.
                The approach you take in tackling these issues determines the welfare of millions.
                Will you expose corrupt officials and dismantle monopolies that exploit consumers?
                Your choices shape the nation’s path, requiring strategic thinking and a strong
                moral compass to guide you through these ethical dilemmas?
              </TypographyCenterSubTitleWrapper>
            </Box>
          </BoxCenterContentMainWrapper>
        </BoxqplSubDivWrapper>
      </BoxqplMainWrapper>

      {/* forth scrion */}
      <BoxqplMainWrapper
        sx={{ backgroundImage: `url(${qplbackgropund4.src}) !important` }}
        className="qplMainWrapper"
      >
        <BoxqplSubDivWrapper sx={{ flexBasis: '100% !important' }}>
          <BoxqplDivAdventureWrapper>
            <BoxCenterContentMainWrapper sx={{ marginRight: '0px !important' }}>
              <Box>
                <TypographyCenterTitleWrapper>Be the Spark of Change</TypographyCenterTitleWrapper>
              </Box>
              <Box>
                <TypographyCenterSubTitleWrapper>
                  End the Rig offers a dynamic experience where your choices shape an economy for
                  the people, not just those in power. Each decision influences healthcare,
                  education, banking, and housing policies for future generations. By championing
                  transparency, fairness, and accountability, you can build a fair and ethical
                  political system that works for us all. The future is yours to mold– investigate
                  wisely, decide courageously, and choose a path the provides access to opportunity
                  and prosperity for all.
                </TypographyCenterSubTitleWrapper>
              </Box>
              <Box display="flex">
                <ButtonPlayNow onClick={handleOnPlayClick}> Play End the Rig</ButtonPlayNow>
              </Box>
            </BoxCenterContentMainWrapper>
          </BoxqplDivAdventureWrapper>
        </BoxqplSubDivWrapper>
      </BoxqplMainWrapper>

      {/* fifth scrion */}
      <BoxqplMainWrapper
        sx={{
          backgroundColor: `rgba(38, 48, 73, 0.99) ! important`,
          [theme.breakpoints.down('sm')]: {
            height: '40vh !important',
          },
          [theme.breakpoints.up('sm')]: {
            height: '42vh !important',
          },
          [theme.breakpoints.up('md')]: {
            height: '50vh !important',
          },
          [theme.breakpoints.up('lg')]: {
            height: '52vh !important',
          },
          [theme.breakpoints.up('xl')]: {
            height: '50vh !important',
          },
          backdropFilter: 'blur(5px)',
          justifyContent: 'center',
        }}
        className="qplMainWrapper"
      >
        <BoxqplDivAdventureWrapper
          sx={{ gap: '30px !important', flexBasis: '95% !important', padding: '5vh' }}
        >
          <Box
            sx={{
              flexBasis: '40%',
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'flex-start',
            }}
          >
            <Box className="header-logo" sx={{ cursor: 'pointer' }} onClick={handleHeaderLogoClick}>
              <StyleLogoImagefooterlogo
                height={100}
                width={100}
                src={endtheRigConfig.logo}
                alt="HOE Icon"
              />
            </Box>
            <Box>
              <TypographyCenterSubTitleWrapper sx={{ textAlign: 'left !important' }}>
                End The Rig is a virtual planet, where people can freely socialize and enjoy
                gameplay without any boundaries or restrictions. One of the best parts about End The
                Rig is that it's for everyone. You can belong no matter who you are or where you
                come from.
              </TypographyCenterSubTitleWrapper>
            </Box>
          </Box>

          <Box
            sx={{
              flexBasis: '40%',
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'flex-start',
            }}
          >
            <Box>
              <TypographyCenterTitleWrapper sx={{ textAlign: 'left !important' }}>
                Company
              </TypographyCenterTitleWrapper>
            </Box>
            <List>
              <ListItem sx={{ paddingTop: '0px' }}>
                <TypographyCenterSubTitleWrapper
                  onClick={() => {
                    window.open(`${PROJECT.privacyPolicy}`, '_blank')
                  }}
                  sx={{
                    textAlign: 'left !important',
                    cursor: 'pointer',
                    padding: '2px !important',
                  }}
                >
                  Privacy Policy
                </TypographyCenterSubTitleWrapper>
              </ListItem>
              <ListItem sx={{ paddingTop: '0px' }}>
                <TypographyCenterSubTitleWrapper
                  onClick={() => {
                    window.open(LegalLinks.TermsAndServices, '_blank')
                  }}
                  sx={{
                    textAlign: 'left !important',
                    cursor: 'pointer',
                    padding: '2px !important',
                  }}
                >
                  Terms & Services
                </TypographyCenterSubTitleWrapper>
              </ListItem>
              <ListItem sx={{ paddingTop: '0px' }}>
                <TypographyCenterSubTitleWrapper
                  onClick={() => {
                    window.open(LegalLinks.Wiki_End_The_Rig, '_blank')
                  }}
                  sx={{
                    textAlign: 'left !important',
                    cursor: 'pointer',
                    padding: '2px !important',
                  }}
                >
                  Wiki For End the Rig
                </TypographyCenterSubTitleWrapper>
              </ListItem>
              <ListItem sx={{ paddingTop: '0px' }}>
                <TypographyCenterSubTitleWrapper
                  onClick={() => {
                    window.open(LegalLinks.CommunityPolicy, '_blank')
                  }}
                  sx={{
                    textAlign: 'left !important',
                    cursor: 'pointer',
                    padding: '2px !important',
                  }}
                >
                  Community Policy
                </TypographyCenterSubTitleWrapper>
              </ListItem>
              <ListItem sx={{ paddingTop: '0px' }}>
                <TypographyCenterSubTitleWrapper
                  onClick={() => {
                    window.open(LegalLinks.Launch_Article_Gitbook, '_blank')
                  }}
                  sx={{
                    textAlign: 'left !important',
                    cursor: 'pointer',
                    padding: '2px !important',
                  }}
                >
                  Launch Article & Gitbook
                </TypographyCenterSubTitleWrapper>
              </ListItem>
              <ListItem sx={{ paddingTop: '0px' }}>
                <TypographyCenterSubTitleWrapper
                  onClick={() => {
                    router.push(ROUTES.ContactUs)
                  }}
                  sx={{
                    textAlign: 'left !important',
                    cursor: 'pointer',
                    padding: '2px !important',
                  }}
                >
                  Contact Us
                </TypographyCenterSubTitleWrapper>
              </ListItem>
            </List>
          </Box>

          {/* <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '59px',
              padding: '12px 16px',
              alignItems: 'left',
              gap: '10px',
              alignSelf: 'stretch',
            }}
          >
            <TypographyCenterTitleWrapper sx={{ textAlign: 'left !important' }}>
              Newsletter
            </TypographyCenterTitleWrapper>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <CustomTextField
                type="email"
                variant="outlined"
                placeholder="Email Address"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ButtonSubscribe onClick={handleOnPlayClick}> Subscribe</ButtonSubscribe>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box> */}
        </BoxqplDivAdventureWrapper>
      </BoxqplMainWrapper>

      {/* Sixth scrion */}
      <BoxqplMainWrapper
        sx={{
          backgroundColor: `rgba(31, 40, 61, 0.99) ! important`,
          height: '5vh !important',
          backdropFilter: 'blur(15px)',
        }}
        className="qplMainWrapper"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '30px',
            flexBasis: '100%',
            paddingLeft: '25px ',
            alignSelf: 'center',
          }}
        >
          <Box
            sx={{
              [theme.breakpoints.down('sm')]: {
                flexBasis: '70%',
              },
              [theme.breakpoints.up('md')]: {
                flexBasis: '85%',
              },
              [theme.breakpoints.up('lg')]: {
                flexBasis: '25%',
              },
            }}
          >
            <TypographyCenterSubTitleWrapper sx={{ fontSize: '1vh !important' }}>
              Copyright © 2024 End The Rig. All right reserved.
            </TypographyCenterSubTitleWrapper>
          </Box>
          <Box>
            {/* <TypographyCenterSubTitleWrapper sx={{ fontSize: '14px !important' }}>
              logo
            </TypographyCenterSubTitleWrapper> */}
          </Box>
        </Box>
      </BoxqplMainWrapper>

      <DialogVideoPlayerMainWrapper open={open} onClose={handleClose}>
        <BoxVideoSubWrapper>
          <CloseIconWprapper onClick={handleClose} />
          <iframe
            loading="lazy"
            src={`https://www.youtube.com/embed/${PROJECT.marketingIntroVideoId}?autoplay=1&rel=0&showinfo=0`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ width: '100%', height: '100%', border: 'none' }}
          />
        </BoxVideoSubWrapper>
      </DialogVideoPlayerMainWrapper>
    </>
  )
}
export default MarketingLandingPage
