import * as React from 'react'
import { Modal, Box, Typography, Button } from '@mui/material'
import Theme from '@theme'
import withProductionCheck from '../../../commons/with-production-check'

type ChangeInflationModalProps = {
  isOpen: boolean
  onCancel: () => void
  onSave: () => void
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: Theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: 24,
  p: 6,
}
const ChangeInflationModal: React.FC<ChangeInflationModalProps> = ({
  isOpen,
  onCancel,
  onSave,
}) => {
  const handleSaveClick = () => {
    onSave()
  }
  return (
    <Modal
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" gutterBottom>
          Change your Inflation model
        </Typography>
        <Typography variant="subtitle1" color="error" gutterBottom>
          WARNING: It is not recommended to change. Are you sure you want to change?
        </Typography>
        <Typography variant="body1" paragraph>
          Make sure to select what is TRUE INFLATION. When selecting what true inflation is needed
          to view the correct way to calculate how much you should receive in your salary.
        </Typography>
        <Typography variant="body2" paragraph>
          The US Gov had high pressure to suppress inflation in the 1980 until today since Social
          Security laws require them to add increases to match inflation. That was a problem, which
          incentivized them to want inflation to be calculated with a low number. It also rewards
          current politicians in place if it appears they have low inflation.
        </Typography>
        <Box display="flex" align-item="center" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={onCancel}
            style={{ marginRight: '20px' }}
          >
            Close
          </Button>
          <Button variant="outlined" color="primary" onClick={handleSaveClick}>
            Change & Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ChangeInflationModal
