import { HOME_PAGE } from '@constant'
import MarketingLandingPage from '@template/GameDashboard/components/Home/Index'
import MarketingDashboardLayout from '@layout/Default/MarketingDashboard'
import { PROJECT, Project } from 'configs'
import DefaultLayout from '@layout/Default/Default'
import { MAIN_MENU_ITEMS, USER_MENU_ITEMS } from '@constant/MainMenuItems'
import TrueInflation from './fixes/worker-defense/true-inflation'
import MedicalExampleTable from './fixes/rigged-medical/examples'
import Tricorder from './tricorder'
import RiddedHqHome from './fixes/rigged-economy'
import CongressMembers from './fixes/congress/members'

// @ts-ignore
export const homeComponentMap = {
  TRUE_INFLATION: <TrueInflation />,
  // eslint-disable-next-line react/jsx-no-undef
  RIGGED_ECONOMY: <RiddedHqHome />,
  MEDICAL_EXAMPLE_TABLE: <MedicalExampleTable />,
  TRICORDER: <Tricorder />,
  CONGRESS_MEMBER: <CongressMembers />,
  // Add more mappings as needed
}
Home.getLayout = (page: React.ReactElement) =>
  PROJECT?.mainDomain === Project.END_THE_RIG ? (
    <MarketingDashboardLayout>{page}</MarketingDashboardLayout>
  ) : (
    <DefaultLayout menuItems={MAIN_MENU_ITEMS} userMenuItems={USER_MENU_ITEMS}>
      {page}
    </DefaultLayout>
  )
export default function Home() {
  return HOME_PAGE ? (
    homeComponentMap[HOME_PAGE] || <MarketingLandingPage />
  ) : (
    <MarketingLandingPage />
  )
}
