/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import { useState } from 'react'
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DashboardIcon from '@mui/icons-material/Dashboard'
import Link from 'next/link'
import { useQuery } from 'react-query'
import theme from '@theme'
import {
  WorkerDefenseTrueInflationAddDataSource,
  WorkerDefenseTrueInflationDataDashboard,
} from '@constant/Routes/Routes'
import { Inflation, InflationGetAllResponse, InflationStatus } from '@api/Inflation'
import {
  getAppInflationApi,
  getDefaultInflationApi,
  getInflationByIdApi,
  getUsOfficialInflationApi,
} from '@api/inflation.api'
import TableSkeleton from '@element/TableSkeleton.tsx/TableSkeleton'
import useCurrentUser from '@hook/user/useCurrentUser'
import ChangeInflationModal from './components/change-inflation-modal'
import withProductionCheck from '../../commons/with-production-check'

const currentYear = new Date().getFullYear()
const TrueInflation = () => {
  const { isAdmin } = useCurrentUser()
  const [showAllInflations, setShowAllInflations] = useState(false)
  const [inflations, setInflations] = useState<Inflation[]>([])
  const [defaultInflationId, setDefaultInflationId] = useState<string>()
  const [defaultInflation, setDefaultInflation] = useState<Inflation>()
  const [officialInflation, setOfficialInflation] = useState<Inflation>()
  const [count, setCount] = useState<number>(0)
  const [inflationStatus, setInflationStatus] = useState<InflationStatus>(InflationStatus.Pending)
  const [page, setPage] = useState<number>(1)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [selectedInflationId, setSelectedInflationId] = useState<string>('')
  const { isLoading, isFetching } = useQuery(
    ['getAppInflationApi'],
    () =>
      getAppInflationApi({
        page,
        limit: rowsPerPage,
        inflationStatus: InflationStatus.Approved,
      }),
    {
      refetchOnMount: true,
      onSuccess(response: InflationGetAllResponse) {
        setInflations(response.data)
      },
    },
  )

  const { isLoading: isLoadingOfficialApiResp, isFetching: isFetchingOfficialApiResp } = useQuery(
    ['getUsOfficialInflationApi'],
    () => getUsOfficialInflationApi(),
    {
      refetchOnMount: true,
      onSuccess(response: Inflation) {
        setOfficialInflation(response)
      },
    },
  )

  const { isLoading: isLoadingDefaultApiResp, isFetching: isFetchingDefaultApiResp } = useQuery(
    ['getDefaultInflationApi'],
    () => getDefaultInflationApi(),
    {
      refetchOnMount: true,
      onSuccess(response: Inflation) {
        if (response && response.id) {
          setDefaultInflationId(response.id)
        }
      },
    },
  )

  const { isLoading: isLoadingDefaultByIdApiResp, isFetching: isFetchingDefaultByIdApiResp } =
    useQuery(
      ['getInflationByIdApi', defaultInflationId],
      () => getInflationByIdApi(defaultInflationId),
      {
        enabled: !!defaultInflationId,
        refetchOnMount: true,
        onSuccess(response: Inflation) {
          setDefaultInflation(response)
          const defaultYearlyInflation = response.yearlyInflations.find(
            (inflation) => inflation.year === currentYear,
          )
          localStorage.setItem(
            'trueInflation',
            defaultYearlyInflation?.inflationPercentage?.toString(),
          )
        },
      },
    )

  const handleInflationSourceChange = (id: string, setDefaultWithoutModal: boolean) => {
    if (setDefaultWithoutModal) {
      setDefaultInflationId(id)
    } else {
      setSelectedInflationId(id) // Set the selected id
      setIsModalOpen(true)
    }
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleSaveInflation = () => {
    if (selectedInflationId) {
      setDefaultInflationId(selectedInflationId)
    }
    closeModal()
  }

  const renderYearlyInflationTable = () => {
    if (!defaultInflation || !officialInflation) {
      return <TableSkeleton row={3} />
    }

    return Array.from({ length: 2023 - 1800 + 1 }, (_, index) => {
      const year = 2023 - index
      const defaultYearlyInflation = defaultInflation.yearlyInflations.find(
        (inflation) => inflation.year === year,
      )
      const officialYearlyInflation = officialInflation.yearlyInflations.find(
        (inflation) => inflation.year === year,
      )

      return (
        <TableRow key={year}>
          <TableCell>{year}</TableCell>
          <TableCell
            style={{
              backgroundColor: theme.palette.yellow1,
            }}
          >
            {defaultYearlyInflation?.inflationPercentage !== undefined
              ? `${defaultYearlyInflation.inflationPercentage} %`
              : '-'}
          </TableCell>
          <TableCell>
            {' '}
            {officialYearlyInflation?.inflationPercentage !== undefined
              ? `${officialYearlyInflation.inflationPercentage} %`
              : '-'}
          </TableCell>
        </TableRow>
      )
    })
  }

  const renderListSourcetable = () => {
    const visibleInflations = showAllInflations
      ? inflations
      : inflations.filter((inflation) => inflation.default || inflation.usOfficial)

    if (isLoading || isFetching) {
      return <TableSkeleton row={3} />
    }

    if (!inflations) {
      return (
        <TableRow>
          <TableCell colSpan={4}>No data available</TableCell>
        </TableRow>
      )
    }

    return visibleInflations.map((inflation) => (
      <TableRow
        key={inflation.id}
        style={{
          backgroundColor:
            inflation.id === defaultInflationId ? theme.palette.yellow1 : 'transparent',
        }}
        onClick={() => handleInflationSourceChange(inflation.id, inflation.default)}
      >
        <TableCell>{inflation.title}</TableCell>
        <TableCell />
        <TableCell>{inflation.description}</TableCell>
      </TableRow>
    ))
  }

  return (
    <>
      {/* Render the modal */}
      <ChangeInflationModal
        isOpen={isModalOpen}
        onCancel={closeModal}
        onSave={handleSaveInflation}
      />
      <Box marginTop={1} marginBottom={4}>
        {/* Main Header */}
        <Typography variant="h4">True Inflation</Typography>
        {isAdmin && (
          <Box display="flex" alignItems="center" justifyContent="space-between" marginTop={0}>
            <Box display="flex" gap={2}>
              <Link href={WorkerDefenseTrueInflationAddDataSource}>
                <Button variant="text" startIcon={<AddCircleOutlineIcon />} size="small">
                  Add Source
                </Button>
              </Link>
              <Link href={WorkerDefenseTrueInflationDataDashboard}>
                <Button variant="text" startIcon={<DashboardIcon />} size="small">
                  Inflation Data Admin
                </Button>
              </Link>
            </Box>
          </Box>
        )}
      </Box>
      <Box display="flex" gap={4}>
        {/* Left Table */}
        <Box sx={{ flex: 1, marginRight: '8px' }}>
          <Typography variant="h6" style={{ padding: '16px' }}>
            True Inflation by Year
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="outlined-header">Year</TableCell>
                  <TableCell className="outlined-header">True Inflation</TableCell>
                  <TableCell className="outlined-header">US Official</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderYearlyInflationTable()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* Right Table */}
        <Box sx={{ flex: 4 }}>
          <Typography variant="h6" style={{ padding: '16px' }}>
            Sources of True Inflation
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '20%' }}>Name</TableCell>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <TableCell sx={{ width: '10%' }}>User's Selected</TableCell>
                  <TableCell sx={{ width: '70%' }}>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderListSourcetable()}</TableBody>
            </Table>
          </TableContainer>
          {!showAllInflations && inflations.length > 2 && (
            <Box display="flex" justifyContent="flex-end" padding="16px">
              <Button
                onClick={() => setShowAllInflations(true)}
                variant="outlined"
                color="primary"
                style={{ marginTop: '16px' }}
              >
                Show More
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default withProductionCheck(TrueInflation)
