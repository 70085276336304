import { Box, Typography, styled } from '@mui/material'
import { ReactNode } from 'react'
import Logo from '@commons/modules/Logo'
import theme from '@theme'

const CustomBox = styled(Box)`
  width: 100%;
  & blockquote {
    width: 100%;
    margin: 0;
    & iframe {
      transform: scale(1.3);
      transform-origin: top center;
      position: relative;
      top: -10px;
    }
  }
`

const Layout = ({
  loading,
  loadingMsg = 'Loading...',
  children,
}: {
  loading?: boolean
  loadingMsg?: string
  children?: ReactNode
}) => (
  <Box sx={{ width: '100%' }}>
    <Box
      sx={{
        p: 1,
        background: theme.palette.grey14,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Logo width="115px" />
    </Box>
    <CustomBox>
      {loading ? (
        <Typography variant="h4" color="grey">
          {loadingMsg}
        </Typography>
      ) : (
        children
      )}
    </CustomBox>
  </Box>
)

export default Layout
