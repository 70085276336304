/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import { useState } from 'react'
import { Box, Tab } from '@mui/material'
import Routes from '@constant/Routes'
import Header from '@module/Header/Header'
import { MUITable } from '@ui/MUITable'
import { useMTPageParams } from '@ui/MUITable/hooks/usePageParams'
import { getCongressMembers } from '@api/congress'

export const representativeColumns = [
  {
    id: 'bioguideId',
    header: 'Bio Guide Id',
    accessorFn: (row) => row.bioguideId,
    size: 50,
  },
  {
    id: 'name',
    header: 'Name',
    accessorFn: (row) => row.name ?? '',
    size: 100,
  },
  {
    id: 'state',
    header: 'State',
    accessorFn: (row) => row.state,
    size: 100,
  },
  {
    id: 'district',
    header: 'District',
    accessorFn: (row) => row.district,
    size: 100,
  },
]

export const senateColumns = [
  {
    id: 'bioguideId',
    header: 'Bio Guide Id',
    accessorFn: (row) => row.bioguideId,
    size: 50,
  },
  {
    id: 'name',
    header: 'Name',
    accessorFn: (row) => row.name ?? '',
    size: 100,
  },
  {
    id: 'state',
    header: 'State',
    accessorFn: (row) => row.state,
    size: 100,
  },
]

export default function CongressMembers() {
  const router = useRouter()
  const { search, pagination, sorting } = useMTPageParams()
  const [value, setValue] = useState<string>('1')

  const { data: representatives, isLoading: loadingRepresentatives } = useQuery(
    ['getRepresentatives', pagination, search, router.query],
    () =>
      getCongressMembers({
        limit: pagination.limit,
        page: pagination.page,
        search,
        congressType: 'REPRESENTATIVE',
        sorting,
      }),
  )

  const { data: senators, isLoading: loadingSenators } = useQuery(
    ['getSenators', pagination, search, router.query],
    () =>
      getCongressMembers({
        limit: pagination.limit,
        page: pagination.page,
        search,
        congressType: 'SENATOR',
        sorting,
      }),
  )

  return (
    <>
      <Header title="Congress Members" />
      <TabContext value={value}>
        <Box>
          <TabList sx={{ mb: 2 }} onChange={(e, v) => setValue(v)}>
            <Tab label="Representatives" value="1" />
            <Tab label="Senators" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          <MUITable
            loading={loadingRepresentatives}
            data={representatives?.data || []}
            rowCount={representatives?.count}
            getRowLinkPath={(row) =>
              `${process.env.NEXT_PUBLIC_CONGRESS_MEMBER_URL}${Routes.MemberProfile.replace(
                '[id]',
                row.id,
              )}`
            }
            onRowClick={(row) => {
              router.push(Routes.MemberProfile.replace('[id]', row.id))
            }}
            columns={representativeColumns}
            enableRowNumbers
            height="calc(100vh - 260px)"
          />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          <MUITable
            loading={loadingSenators}
            data={senators?.data || []}
            rowCount={senators?.count}
            getRowLinkPath={(row) =>
              `${process.env.NEXT_PUBLIC_CONGRESS_MEMBER_URL}${Routes.MemberProfile.replace(
                '[id]',
                row.id,
              )}`
            }
            onRowClick={(row) => router.push(Routes.MemberProfile.replace('[id]', row.id))}
            columns={senateColumns}
            enableRowNumbers
            height="calc(100vh - 260px)"
          />
        </TabPanel>
      </TabContext>
    </>
  )
}
